<!--重要指标-->

<template>
    <widget-framework v-if="dataLoaded" title="重要指标" :show-border="false">
        <template #content>
            <el-row :gutter="24" style="height: 100%; width: 100%">
                <el-col :span="12" v-for="(indicator, index) in indicators" :key="index" style="height: 33%;">
                    <div class="item">
                        <el-col :span="8" class="header">
                            <span class="item-no">{{index + 1}}</span><br/>
                            <span class="item-type">{{indicator.typeName}}</span>
                        </el-col>
                        <el-col :span="16" class="content">
                            <el-col :span="24">
                                <span class="item-name">售电量波动：</span>
                                <span class="item-value">{{indicator.powerSalesWave}} kVA</span>
                            </el-col>
                            <!--<el-col :span="24">
                                <span class="item-name">售电阿收益波动：</span>
                                <span class="item-value">{{indicator.powerSalesIncomeWave}} 元</span>
                            </el-col>-->
                        </el-col>
                    </div>
                </el-col>
            </el-row>
        </template>
    </widget-framework>
    <dv-loading v-else></dv-loading>
</template>

<script>
import WidgetFramework from '@/components/widgets/WidgetFramework';

export default {
    name: 'ImportantIndicator',
    components: {
        WidgetFramework
    },
    data() {
        return {
            dataLoaded: false,
            indicators: []
        };
    },
    mounted() {
        //模拟后台请求
        setTimeout(() => {
            const ret = {
                code: 0,
                msg: '',
                data: [
                    {type: 1, typeName: '计划停电', powerSalesWave: 0, powerSalesIncomeWave: 0},
                    {type: 1, typeName: '非计划停电', powerSalesWave: 0, powerSalesIncomeWave: 0},
                    {type: 1, typeName: '业扩业务', powerSalesWave: 0, powerSalesIncomeWave: 0},
                    {type: 1, typeName: '减容业务', powerSalesWave: 0, powerSalesIncomeWave: 0},
                    {type: 1, typeName: '暂停业务', powerSalesWave: 0, powerSalesIncomeWave: 0},
                    {type: 1, typeName: '暂换业务', powerSalesWave: 0, powerSalesIncomeWave: 0},
                ]
            };
            this.indicators = ret.data;
            this.dataLoaded = true;
        }, 1000);
    }
};
</script>

<style lang="less" scoped>
@import '~@/assets/style/variables.less';
.item {
    background-color: rgba(0, 250, 247, 0.2);
    height: calc(100% - 10px);
    width: 100%;
    display:flex;
    justify-content:center;
    align-items:center;
    font-size: 12px;

    .header {
        text-align: center;
        line-height: 25px;

        .item-no {
            color: @blue;
            font-size: 22px;
            font-weight: bold;
        }
        .item-type {
            color: @white;
        }
    }
    .content {
        line-height: 25px;
        .item-name {
            color: @cyan;
        }
        .item-value {
            color: @green
        }
    }
}

</style>
